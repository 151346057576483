<template>
     <v-card>
        <v-card-title>
           <h2 style="min-height:18px !important;" class="pa-3">{{ $t('activeLeads') }}</h2>
        </v-card-title>
        <v-card-text class="d-flex align-center pb-8">
            <div style="width:40%; max-height:180px">
                <DonutComponent v-if="dataSet2" :dataSet="dataSet2" :height="180" :legend="false"/>
            </div>
            <div class="legend  d-flex  align-center" style="width:60%;">
                <div>
                    <div class="d-flex mb-2"  style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(39, 199, 212)'"></span>{{ _.get(stats , 'active.details.to_deal') ? stats.active.details.to_deal : 0 }} {{$t('to_deal')}}
                    </div>
                    <div class="d-flex mb-2" style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(249, 107, 43)'"></span>{{ _.get(stats , 'active.details.rdv_to_come') ? stats.active.details.rdv_to_come : 0 }} {{$t('rdv_to_come')}}
                    </div>
                    <div class="d-flex mb-2"  style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(125, 79, 254)'"></span>{{ _.get(stats , 'active.details.rdv_to_confirm') ? stats.active.details.rdv_to_confirm : 0 }} {{$t('rdv_to_confirm')}}
                    </div>
                    <div class="d-flex" style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(217, 84, 79)'"></span>{{ _.get(stats , 'active.details.feedback_rdv') ? stats.active.details.feedback_rdv : 0 }} {{$t('feedback_rdv')}}
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import DonutComponent from '@/components/graphs/DonutComponent';
export default {
    name: 'ActiveLeadComponent',
    components : {DonutComponent},
    props: ['stats'],
    data() {
        return {
            dataSet:{
                labels: ["A traiter", "Rendez-vous à venir", "Rendez-vous à confirmer" , "Retour sur rendez-vous"],
                total:450,
                datasets : [{
                    label: 'My First Dataset',
                    data: [300, 150, 12, 30],
                    backgroundColor: [
                        'rgb(39, 199, 212)',
                        'rgb(249, 107, 43)',
                        'rgb(125, 79, 254)',
                        'rgb(217, 84, 79)'
                    ],
                    hoverOffset: 4
                }],
                options: {
                    responsive:true,
                    maintainAspectRatio: false, 
                    onClick:this.handle,
                    layout: {
                        padding: {
                            left: 0,
                            right: 0, 
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: false
                    },
                    animation: {
                        duration:0,
                    },
                    title:{
                        display: false,
                        text: "test"
                    },
                    scales:{
                        yAxes: [{
                            beginAtZero: true,
                            stacked: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            beginAtZero: true,
                            stacked: false
                        }],
                    },
                }
            }, 
        }
    },
    methods: {
    },
    computed: {
        dataSet2() {
            if(this.stats) {
                let dataset = this._.cloneDeep(this.dataSet);
                dataset.total = this.stats.active.total;
                dataset.datasets[0].data = [this.stats.active.details.to_deal, this.stats.active.details.rdv_to_come,this.stats.active.details.rdv_to_confirm,this.stats.active.details.feedback_rdv];
                if(this.stats.active.details.to_deal == 0 && this.stats.active.details.rdv_to_come == 0 && this.stats.active.details.rdv_to_confirm == 0 && this.stats.active.details.feedback_rdv == 0){
                    dataset.datasets[0].data = [100];
                    dataset.labels = ['Aucune donnée'];
                    dataset.datasets[0].backgroundColor = ['#DDD'];
                }
                return dataset;
            }
            return false
        }
    },
    
};
</script>

<style>
/* Your component styles go here */
</style>