<template>
     <v-card>
        <v-card-title>
           <h2 style="min-height:18px !important;" class="pa-3">{{ $t('closedLeads') }}</h2>
        </v-card-title>
        <v-card-text class="d-flex align-center pb-8">
            <div style="width:40%; max-height:180px">
                <DonutComponent v-if="dataSet2" :dataSet="dataSet2" :height="180" :legend="false"/>
            </div>
            <div class="legend  d-flex  align-center" style="width:60%;">
                <div>
                    <div class="d-flex mb-2"  style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(91, 184, 93)'"></span>{{ _.get(stats , 'closed.details.success') ? stats.closed.details.success : 0 }} {{$t('success')}}
                    </div>
                    <div class="d-flex" style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(217, 84, 79)'"></span>{{ _.get(stats , 'closed.details.fail') ? stats.closed.details.fail : 0 }} {{$t('fail')}}
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import DonutComponent from '@/components/graphs/DonutComponent';
export default {
    name: 'ClosedLeadComponent',
    components : {DonutComponent},
    props: ['stats'],
    data() {
        return {
            dataSet:{
                labels: ["Succès", "Echec"],
                total:450,
                datasets : [{
                    label: 'My First Dataset',
                    data: [300, 150],
                    backgroundColor: [
                       'rgb(91, 184, 93)',
                        'rgb(217, 84, 79)'
                    ],
                    hoverOffset: 4
                }],
                options: {
                    responsive:true,
                    maintainAspectRatio: false, 
                    onClick:this.handle,
                    layout: {
                        padding: {
                            left: 0,
                            right: 0, 
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: false
                    },
                    animation: {
                        duration:0,
                    },
                    title:{
                        display: false,
                        text: "test"
                    },
                    scales:{
                        yAxes: [{
                            beginAtZero: true,
                            stacked: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            beginAtZero: true,
                            stacked: false
                        }],
                    },
                }
            }, 
        }
    },
    methods: {
    },
    computed: {
        dataSet2() {
            if(this.stats) {
                let dataset = this._.cloneDeep(this.dataSet);
                dataset.total = this.stats.closed.total;
                dataset.datasets[0].data = [this.stats.closed.details.success, this.stats.closed.details.fail];

                if(this.stats.closed.details.success == 0 && this.stats.closed.details.fail == 0){
                    dataset.datasets[0].data = [100];
                    dataset.labels = ['Aucune donnée'];
                    dataset.datasets[0].backgroundColor = ['#DDD'];
                }

                return dataset;
            }
            return false
        }
    },
    
};
</script>

<style>
/* Your component styles go here */
</style>