<template>
    <div>
       
        <Planning 
            type="DISPLAY_RDV"
            :genericUnavailable="genericUnavailable"
            :operationId="0"
            :operations="operations"
            :appointements="appointements"
            :creneau="creneau"
            :vendeurId="vendeurId"
            :vendors="vendors"
            @changeVendorDisplay="changeVendorDisplay"
            @refreshRdvList="refreshRdvList"
            @getPlanningIndispo="getPlanningIndispo"
            @addRdv="openOperationSelection"
            @openVendorIndispo="openVendorIndispo"
            :key="'planning_'+planningKey"
            mode="inner"/>
            <v-dialog width="700" persistent v-model="selectOperation">
                <v-card>
                    <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                        <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                            <div class="d-flex align-center">
                                <v-toolbar-title class="d-flex align-center">{{$t("selectOperation")}}</v-toolbar-title>
                            </div>
                            <div>
                                <v-btn outlined dark depressed @click.native="selectOperation = false">{{  $t('cancel') }}</v-btn>
                                <v-btn outlined dark depressed :disabled="isDisabled" @click="addRdv" class="ml-3">{{ $t('save') }}</v-btn>
                            </div>
                        </div> 
                    </v-toolbar>
                    <v-card-text>
                        <v-tabs v-model="tab"
                        background-color="transparent">
                            <v-tab>{{$t('selectLead')}}</v-tab>
                            <v-tab>{{$t('manualCreation')}}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <span v-if="leadsAvailable.length  > 0">
                                    <div class="mt-3">{{$t('whichLead')}}</div>
                                    <v-autocomplete hide-details required dense solo flat class="input-form" v-model="leadSelected" :items="leadsAvailable" ></v-autocomplete>
                                </span>
                                <span v-else>
                                    <v-alert border='left' :icon="'$warning_s'" text width="600" type="warning" class="mb-0 mt-3">
                                        <h6 style=" padding-bottom: 0;" class="warning--text" v-html="$t('noLeadOpennedOnYourOperations')"></h6>
                                    </v-alert>
                                </span>
                            </v-tab-item>
                            <v-tab-item>
                                <span v-if="operationToSelect.length  > 0">
                                    <div class="mt-3">{{$t('whichOperationSetLead')}}</div>
                                    <v-autocomplete hide-details required dense solo flat class="input-form" v-model="operationSelected" :items="operationToSelect" ></v-autocomplete>
                                </span>
                                <span v-else>
                                    <h5 class="error--text mt-6 text-center">{{ $t('notinanyoperation') }}</h5>
                                </span>
                                <span v-if="operationSelected">
                                    <div class="mt-3">{{$t('whichPlaceSetLead')}}</div>
                                    <v-autocomplete hide-details required dense solo flat class="input-form" v-model="placeSelected" :items="places" ></v-autocomplete>
                                </span>
                            </v-tab-item>
                        </v-tabs-items>
                        
                    </v-card-text>
                </v-card>
            </v-dialog>
            <Loader v-if="showLoader"></Loader>
            <v-dialog v-model="dialogUserIndispo" fullscreen scrollable transition="dialog-bottom-transition">
            <v-card rounded="lg">
                <v-toolbar tile dark class="bg-gradient">
                    <v-btn
                        depressed
                        color="white"
                        class="square mr-2"
                        text
                        @click.native="dialogUserIndispo = false"
                    >
                        <v-icon dark small>$prev</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('userIndispoTitle')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                     <v-btn depressed outlined color="white" class="mr-3" @click="dialogUserIndispo = false">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white"  @click="recordUserIndispo()">
                        {{$t('recordRdvLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-0">
                    <Planning ref="planning_indispo"
                    :type="'INDISPO_USER'"
                    :key="planningKey"
                    :genericUnavailable="userUnavailable"
                    :isMine="false"
                    :vendeurId="vendeurId"
                    :creneau="60"
                    :operations="[]"
                    :appointements="[]"
                    @addItem="addItem"
                    @editItem="editItem"
                    @removeUnavailable="removeUnavailable"
                    @deleteUserIndispo="deleteUserIndispo"
                    @getPlanningIndispo="getUserIndispo"
                    mode="popup"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Planning from '@/components/planning/Planning';
import GenericDataService from '@/services/GenericDataService'
import planningMixins from '@/mixins/planningMixins'
import dateManipulation from '@/mixins/dateManipulation'

import Loader from '@/components/Loader.vue'

export default {
    name: "globalPlanning",
    mixins:[planningMixins, dateManipulation],
    components: {Planning, Loader},
    data() {
        return {
            genericUnavailable: [],
            operations:[],
            appointements:[],
            creneau:"60",
            planningKey:0,
            vendeurId: null,
            selectOperation: false,
            operationSelected: null,
            operationToSelect: [],
            entitySelected: null,
            entities: [],
            itemTemp: {},
            placeSelected: null,
            places: [],
            vendors: [],
            leadsAvailable: [],
            leadSelected: null,
            tab:0,
            showLoader : false,
            dialogUserIndispo: false,
            userUnavailable: [],
            toSave:[],
        }
    },
    mounted() {
        GenericDataService.postData('/globalplanning/getVendorsAvailable').then((response) => {
            this.vendors = response.data.data
            if(this.$route.query.vendor){
                this.vendeurId = this.$route.query.vendor
            } else {
                if(this.$func.hasRight('profile/is_vendor') && !this.$func.hasRight('profile/is_manager')){
                    this.vendeurId = this.$store.state.auth.user.id
                } else {
                    this.vendeurId = this.vendors[0].value
                }
            }
            
            GenericDataService.postData('/globalplanning/getOpenSuperleads?vendor_id='+this.vendeurId).then((response) => {
                this.leadsAvailable = response.data.data
            })
        })
       
    },
    computed: {
        isDisabled(){
            if(this.tab == 1)
                return !this.placeSelected
            else if(this.tab == 0)
                return !this.leadSelected
        }
    },
    watch: {
        selectOperation(val){
            if(val && this.operationToSelect.length == 0){
                GenericDataService.getData('/superlead/_getSelectList?forField=operation_id&fv2=1&filter[status]=IN_PROGRESS&vendor_id='+this.vendeurId).then((response) => {
                    this.operationToSelect = response.data.data
                    if(this.operationToSelect.length == 1){
                        this.operationSelected = this.operationToSelect[0].value
                    }
                })
            }
            if(!val){
                this.operationSelected = null
                this.placeSelected = null
                this.itemTemp = {}
            }
        },
        operationSelected(val){
            if(val){
                GenericDataService.getData('operation/'+this.operationSelected+'/getPlacesWithVendors?vendor_id='+this.vendeurId).then((response) => {
                    this.places = response.data.data
                    if(this.places.length > 0){
                        this.placeSelected = this.places[0].value
                    }
                })
            }
        }
    },
    methods: {
        recordUserIndispo() {
            GenericDataService.postData('/user/'+this.vendeurId+'/setHours', this.toSave).then((response) => {
                let payload = {
                    start: this.planningStartDate,
                    nbdays: this.planningNbDays,
                }
                GenericDataService.postData('/user/'+this.vendeurId+'/getHours', payload).then((response) => {
                    this.toSave = []
                    this.genericUnavailable = response.data.data.slots
                    this.userUnavailable = response.data.data.slots
                    this.planningKey++
                    this.dialogUserIndispo = false
                })
            })
        },
        openVendorIndispo(){
            this.dialogUserIndispo = true;
        },
        changeVendorDisplay(payload) {
            this.vendeurId = payload.user_ids[0]
            GenericDataService.postData('/globalplanning/getRdvList?vendor_id='+this.vendeurId, payload).then((response) => {
                this.appointements = response.data.data
                GenericDataService.postData('/user/'+this.vendeurId+'/getHours', payload).then((response) => {
                    this.userUnavailable = response.data.data.slots.concat(this.toSave)
                    GenericDataService.postData('/globalplanning/getOpenSuperleads?vendor_id='+this.vendeurId).then((response) => {
                        this.leadsAvailable = response.data.data
                        this.planningKey++
                    })
                });
            })
        },
        openOperationSelection(item){
            this.itemTemp = item
            this.selectOperation = true
            ///operation/378/getSelectListEntities
        },
        addRdv(){
            this.showLoader = true
            this.selectOperation = false
            if(this.tab == 1) {
                let placeObj = this.places.find((e) => e.value == this.placeSelected)
                let rdv = {}
                rdv.type = "RDV"
                rdv.date_start = this.itemTemp.date+" "+this.itemTemp.start
                rdv.date_end = this.itemTemp.date+" "+this.itemTemp.end
                rdv.operation_id = this.operationSelected
                rdv.place = placeObj.dataPlace
                if(this.$store.state.auth.currentUser.loggedAs){
                    rdv.user_id = this.$store.state.auth.currentUser.loggedAs.id
                } else {
                    rdv.user_id = this.$store.state.auth.currentUser.profile.id
                }
                //rdv.place = item.place
                rdv.status = 'DRAFT'
                rdv.visio = "0"
                rdv.links = {}
                        
                if(this.itemTemp.ref_id){
                    rdv.links.user = [parseInt(this.itemTemp.ref_id)]
                }
                let payload = {};
                    payload.operation_id = this.operationSelected;
                    payload.entity_id = placeObj.dataPlace.config.entity_id[0];
                    payload.rdv = rdv

                GenericDataService.postData('/superlead/saveStep1', payload).then((response) => {
                    this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                    this.showLoader = false
                })
            } else if (this.tab == 0) {
                //lead existant on ajoute/déplace le rdv sur l'opération du lead
                let payload = {};
                payload.lead_id = this.leadSelected;
                payload.date_start = this.itemTemp.date+" "+this.itemTemp.start
                payload.date_end = this.itemTemp.date+" "+this.itemTemp.end
                payload.user_id = this.vendeurId;
                GenericDataService.postData('/globalplanning/editSuperlead', payload).then((response) => {
                    this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                    this.showLoader = false
                })

            }
            
        },
        refreshRdvList(vendorIds){
            // GenericDataService.postData('/globalplanning/getRdvList?vendor_id='+this.vendeurId, payload).then((response) => {
            //     this.appointements = response.data.data
            // })
        },
        getPlanningIndispo(payload) {
            if(this.vendeurId){
                GenericDataService.postData('/globalplanning/getRdvList?vendor_id='+this.vendeurId, payload).then((response) => {
                    this.appointements = response.data.data
                })

                GenericDataService.postData('/user/' + this.vendeurId + '/getUserDispo'+(this.$func.hasRight('lead/update_duration') ? '?allWeek=true': ''), payload).then((response) => {
                    this.genericUnavailable = this.$func.hasRight('lead/update_duration') ? [] : response.data.data.hours_reversed
                });
            }    
        },
        deleteUserIndispo(slot){
            if(slot.id){
                slot.type = "delete"
                this.toSave.push(slot)
            } else {
                let itemToDelete = this.toSave.find((e) => (e.day == slot.day &&  e.start == slot.start))
                itemToDelete.type = "delete"
            }
            let dayItem = this.userUnavailable.findIndex((e) => (e.day == slot.day &&  e.start == slot.start))
            this.userUnavailable.splice(dayItem, 1)
        },
        getUserIndispo(payload){
            this.userStartDate = payload.from
            this.userNbDays = payload.nbdays
            let payload2 = {
                start: payload.from,
                nbdays: payload.nbdays,
            }
            GenericDataService.postData('/user/'+this.vendeurId+'/getHours', payload2).then((response) => {
                this.userUnavailable = response.data.data.slots.concat(this.toSave)
            })
        },
        editItem(event, itemData){
            let itemIndex = this.userUnavailable.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.userUnavailable.splice(itemIndex, 1)
            this.userUnavailable.push(itemData)

            let toSaveIndex = this.toSave.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.toSave.splice(toSaveIndex, 1)
            this.toSave.push(itemData)
        },
        //Ajout d'une indispo utilisateur
        addItem:function(event, unavailableData) {
            let slot = this.userUnavailable.find((e) => e.date == unavailableData.date)
            let item = this._.cloneDeep(unavailableData);
            //check si une indispo est présente avant et apres
            let itemBefore = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.end == item.start ||
                (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                )
            );
            let itemAfter = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.start == item.end ||
                    (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                )
            );
            let itemUnder = this.userUnavailable.filter((e) => e.date == unavailableData.date && this.parseHourToFloat(e.start) > this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) < this.parseHourToFloat(item.end) && e.end != "24:00" && item.source == e.source && e.type == item.type && e.value == item.value)
            //check si on a deja préenregistré ce slot => on le supprime pour mieux le remettre
            // if(unavailableData.id) {
            //     let toSplice = this.toSave.findIndex((e)=> e.id == unavailableData.id)
            //     if(toSplice){
            //         this.toSave.splice(toSplice, 1)
            //     }
            // }

            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                itemBefore.end = itemAfter.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }
                if(itemAfter.id) {
                    itemAfter.type = "delete"
                    this.toSave.push(itemAfter)
                } else {
                    let itemAfterToSaveIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                    this.toSave.splice(itemAfterToSaveIndex, 1)
                }
                this.userUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                //let itemBeforeIndex = this.toSave
                itemBefore.end = item.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }

            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
                if(itemAfter.id){
                    this.toSave.push(itemAfter)
                }
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++){
                    let itemUnderIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                    if(itemUnder[index].id){
                        itemUnder[index].type = "delete"
                        this.toSave.push(itemUnder[index])
                    } else{
                        let toSaveItemUnderIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                        this.toSave.splice(toSaveItemUnderIndex, 1)
                    }
                    this.userUnavailable.splice(itemUnderIndex, 1)
                }
                this.userUnavailable.push(item);
                this.toSave.push(item)
            } else { // sinon on le push
                this.userUnavailable.push(item);
                this.toSave.push(item)
            }
        },
        //Suppression d'une indispo -------------------------------------------------------------- TOBEREDONE
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.find((e) => e.day == slot.day)
            let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            dayItem.slots.splice(slotIndex, 1)
        },
    }
}
</script>
<style lang="scss">
    
</style>