<template>
    <v-card class="h-100  d-flex flex-column">
        <v-card-title>
           <h2 style="min-height:18px !important;" class="pa-3">{{ $t('indicators') }}</h2>
        </v-card-title>
        <v-card-text class="d-flex flex-column flex-grow-1 justify-space-between" v-if="this.stats">
            <v-alert border="left" text color="error">
                <div class="d-flex align-center">
                    <div style="width:70%" class="pl-6">
                        <v-icon color="error" size="40">
                            $warning_d
                        </v-icon>
                        <div class="mt-2" style="font-size:20px;"><b>{{ $t('sufferingLeads') }}</b></div>
                    </div>
                    <h5 class="error--text flex-grow-1 pr-6 titleIndicator">{{ this.stats.suffering }}%</h5>
                </div>
            </v-alert>
            <v-alert border="left" text color="warning">
                <div class="d-flex align-center">
                    <div style="width:70%" class="pl-6">
                    <v-icon color="warning" size="40">
                        $bulldozer_d
                    </v-icon>
                    <div class="mt-2" style="font-size:20px;"><b>{{ $t('leadNotDeal') }}</b></div>
                </div>
                <h5 class="warning--text flex-grow-1 pr-6 titleIndicator">{{ this.stats.lead_not_deal }}%</h5>
            </div>
            </v-alert>
            <v-alert border="left" text color="info">
                <div class="d-flex align-center">
                    <div style="width:70%" class="pl-6">
                    <v-icon color="info" size="40">
                        $lock_d
                    </v-icon>
                 <div class="mt-2" style="font-size:20px;"><b>{{ $t('closedLeads') }}</b></div>
                </div>
                <h5 class="info--text flex-grow-1 pr-6 titleIndicator">{{ this.stats.closed_leads}}%</h5>
            </div>
            </v-alert>
            <v-alert border="left" text color="success">
                <div class="d-flex align-center">
                    <div style="width:70%" class="pl-6">
                    <v-icon color="success" size="40">
                        $thumbs_up_d
                    </v-icon>
                    <div class="mt-2" style="font-size:20px;"><b>{{ $t('transformationRate') }}</b></div>
                </div>
                <h5 class="success--text flex-grow-1 pr-6 titleIndicator">{{ this.stats.transformation_rate}}%</h5>
            </div>
            </v-alert>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'ActiveLeadComponent',
    components : {},
    props: ['stats'],
    data() {
        return {
           
        }
    },
    methods: {
    },
    computed: {
        
    },
    
};
</script>

<style>
    .titleIndicator {
        font-size:32px; 
        line-height:32px; 
        padding-bottom:0px; 
        text-align:right;
    }
</style>