<template>
   <div class="pt-2 agendaHome">
        <v-card rounded="lg">
            <v-card-title class="" style="border-bottom:1px solid #ddd">
                <h5 class="pb-0"><b>{{$t('agenda')}}</b></h5>
                <v-spacer></v-spacer>
                <div class="indispoBtn" style="font-weight:normal;" v-if="$route.meta.title == 'home' && !$func.hasRight('profile/is_marketing')">
                    <v-btn @click="setIndispo" small depressed color="primary">{{ $t('set-indisponibility') }}</v-btn>    
                </div>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <div class="d-flex align-center justify-space-between mb-2">
                            <h6 class="monthLabel pb-0">{{ month }}</h6>
                            <div>
                                <v-btn fab depressed class="mr-2" x-small @click="previousWeek">
                                    <v-icon x-small>
                                        $prev
                                    </v-icon>
                                </v-btn>
                                <v-btn fab depressed x-small @click="nextWeek">
                                    <v-icon x-small>
                                        $next
                                    </v-icon>
                                </v-btn>
                                
                            </div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <v-card :class="(dateValue == date ? 'active' : '')+' flex-shrink-0 mr-1 cardDay'" @click="changeDate(dateValue)" v-for="(dateValue, index) in days" :key="'date_'+dateValue">
                                <v-card-text class="pa-3 py-4">
                                    <div class="dayNumber text-center">{{ dateValue.slice(-2) }}</div>
                                    <div class="dayText text-center">{{ new Intl.DateTimeFormat("fr-FR", {weekday: 'short'}).format(new Date(dateValue)) }}</div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <h6 class="monthLabel pb-0">{{ (rdvs.length > 0) ? rdvs.length +' '+ $t('rdvs') : $t('rdvs') }}</h6>
                        <div v-if="rdvs.length > 0" class="mt-2">
                            <div v-for="(rdv, index) in rdvs" :key="rdv.id" @click="openSuperlead(rdv)" @mouseover="testhover(rdv.id, true)" @mouseout="testhover(rdv.id, false)" :class="'pa-3 mb-2 rdvDiv '+(rdvHover[rdv.id] ? 'active' : '')">
                                
                                <div class="d-flex pl-2">
                                    <v-img max-width="40" contain :src="rdv.visio == '1' ? '/img/visio.png' : '/img/presentiel.png'"></v-img>
                                    <div class="ml-4">
                                        <p class="pb-0 black--text subtitle">{{ getHourMinutesFromDate(rdv.date_start) }} - {{ rdv.links_hydrated.contact[0].title }}</p>
                                        <p class="mb-0">{{ $t('with') }} {{ rdv.links_hydrated.user[0].title }}</p>
                                    </div>
                                </div>
                                <v-btn fab depressed class="mr-3" x-small>
                                    <v-icon x-small>
                                        $next
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-alert v-else border="left" class="mt-2" color="info" text>
                            {{ $t('nordvs') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>        
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name: "homeCalendarComponent",
    props:["data"],
    mixins:[dateManipulation],
    data() {
        return {
            type:'week',
            focus:'',
            days: [],
            date: "",
            month: "",
            rdvs:[],
            rdvHover:{}
        }
    },
    created() {
        this.days = this.selectWeekDays(new Date());
        this.date = this.formatDate(new Date())
        this.month = this.$func.capitalizeFirstLetter(new Intl.DateTimeFormat("fr-FR", {month: 'long'}).format(new Date(this.date))) + " " + new Date(this.date).getFullYear()
        if(this.data)
            this.rdvs = this._.cloneDeep(this.data);
    },
    watch: {
        days(val){
            if(val.includes(this.date)){
                this.date = this.formatDate(new Date())
            } else {
                this.date = this.formatDate(new Date(val[0]))
            }
            
            this.month = this.$func.capitalizeFirstLetter(new Intl.DateTimeFormat("fr-FR", {month: 'long'}).format(new Date(this.date))) + " " + new Date(this.date).getFullYear()
        },
        date(val){
            GenericDataService.postData('/home/getRdvs', {filters : {date: val}}).then((response) => {
                this.rdvs = response.data.data
            })
        }
    },
    methods: {
        setIndispo(){
            this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", !this.$store.state.agenda.openIndispoDialog);
        },
        testhover(id, value){
            this.$set(this.rdvHover, id, value)
        },
        goToContact(contactId){
            this.$router.push({name: 'ContactDetail', params: {contact_id : contactId}})
        },
        changeDate(date){
            this.date= date
            this.month = this.$func.capitalizeFirstLetter(new Intl.DateTimeFormat("fr-FR", {month: 'long'}).format(new Date(this.date))) + " " + new Date(this.date).getFullYear()
        },
        selectWeekDays(date) {
            var i,
                d = new Date(date),
                a = [];
            d.setDate(d.getDate() - d.getDay());   // Set date one day before first day of week
            for (i=1; i<8; i++) {
                d.setDate(d.getDate() + 1);
                let addDate =  this.formatDate(new Date(d.valueOf())) // Add one day
                a.push(addDate);
            }
            return a;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        nextWeek(){
            let lastDay = new Date(this.days.pop());
            lastDay.setDate(lastDay.getDate() + 1);
            this.days = this.selectWeekDays(lastDay);
        },
        previousWeek(){
            let firstDay = new Date(this.days.shift());            
            firstDay.setDate(firstDay.getDate() - 2);
            this.days = this.selectWeekDays(firstDay);
        },
        openSuperlead(rdv) {
            GenericDataService.getData("superlead/getByRdvId?id=" + rdv.id + ('&operation_id=' + (rdv.operation_id.id ? rdv.operation_id.id : rdv.operation_id))).then((response)=> {
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        }
    },
}
</script>
<style lang="scss">
    .agendaHome {
        .v-btn--fab.v-size--x-small {
            width: 25px !important;
            height:25px !important;
        }
        .v-calendar-daily__intervals-head {
            display:none;
        }
        .v-calendar-daily__body {
            display:none;
        }
        .dayNumber {
            font-weight:900;
            font-size:22px;
        }
        .cardDay {
            min-width:50px !important;
            flex-grow:1;
            .dayNumber, .dayText {
                    color: black !important;
                }
            &.active {
                background-color: var(--v-pink-base) !important;
                .dayNumber, .dayText {
                    color: white !important;
                }
                
            }
        }
        .rdvDiv {
            cursor: pointer;
            border : 1px solid #EEE;
            border-radius:5px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            &.active {
                background-color:#EEE;
            }
        }
    }
    
</style>