<template>
    <v-row v-if="packId == 1" class="pa-6" :key="statsMarketing+'2'">
        <v-col cols="6">
            <marketingMsgSent v-if="stats && stats.data" :msgSent="stats.data.message_sent" />
        </v-col>
        <v-col cols="6">
            <marketingStats v-if="stats && stats.data" :stats="stats.data.stats" />
        </v-col>
        <v-col cols="12">
            <marketingRetroplanning v-if="stats && stats.data" :retroplannings="stats.data.retroplanning" />
            <alertHomeComponent v-if="stats && stats.data && alertAvailable" :alerts="stats.data.alerts" />
        </v-col>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="packId == 2 || (packId == 3 && $func.hasRight('profile/is_marketing'))" :key="statsMarketing+'1'">
        <v-col cols="12">
            <v-row class="pa-4">
                <template v-for="(filter, index) in filtersMarketing">
                    <vDaterangePicker v-if="filter.type == 'dateperiod'" mode="past" v-model="filter.value" @change="reloadData" class="mr-6" :label="index" :key="'filter_'+index"/>
                    <v-select v-else-if="filter.type == 'select'" :no-data-text="$t('noData')" multiple v-model="filter.value" class="mr-6 mt-0 pt-0"  @change="reloadData" :items="filter.items" style="max-width:200px;" :label="$t(index)" :key="'filterdate_'+index"/>
                </template>
            </v-row>
        </v-col>
        <v-col cols="12" class="px-2">
            <v-row>
                <v-col :cols="$func.hasRight('profile/is_manager') ? '3' : '4'" v-for="(stat, index) in stats.stats" :key="index" class="pa-1">
                    <marketingStatPack :data="stat" style="height:100%;" @calculateRoi="calculRoi"/>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col cols="6" v-if="$func.hasRight('profile/is_manager')" class="pa-1">
                    <div class="px-1 pt-1 pb-1 pt-0" style="height:100%;" v-if="stats && stats.topOpe">
                        <v-card rounded="lg" class="h-100">
                            <v-card-title class="align-center">
                                <h5 class="pb-0 w-100 text-center primary--text" style="width:100%;"><b>{{ $t('top3campaign') }}</b></h5>
                            </v-card-title>
                            <v-card-text>
                                <table style="width:100%;">
                                    <tr v-for="n in 3">
                                        <td class="text-center">
                                            <a v-if="stats.topOpe[n - 1]" @click="$router.push({name: 'OperationDetail', params: {operation_id: stats.topOpe[n - 1].id}})">{{ stats.topOpe[n - 1].label }}</a>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="text-center">{{ stats.topOpe[n - 1] ? stats.topOpe[n - 1].costpersubmissions+"€/"+$t('submission') : "-" }}</td>
                                        <td class="text-center">{{ stats.topOpe[n - 1] ? stats.topOpe[n - 1].submissions+" "+$t('submission') : "-" }}</td>
                                    </tr>
                                    
                                </table>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="6" v-if="$func.hasRight('profile/is_manager')" class="pa-1">
                    <div class="px-1 pt-1 pb-1 pt-0" style="height:100%;">
                        <v-card rounded="lg">
                            <v-card-title class="align-center">
                                <h5 class="pb-0 w-100 text-center primary--text" style="width:100%;"><b>{{ $t('perfomencesSources') }}</b></h5>
                            </v-card-title>
                            <v-card-text class="pb-0">
                                <div style="max-height: 200px; position:relative;" class="d-flex align-center mb-2" :key="graphKey">
                                    <div style="width:40%;">
                                        <DonutComponentROI style="height:150px;" :dataSet="dataSet" :legend="false"/>
                                    </div>
                                    <div class="legend" style="width:55%">
                                        <div class="mb-2"><v-avatar size="20" color="rgb(255, 99, 132)" class="mr-2"></v-avatar> EMAIL</div>
                                        <div class="mb-2"><v-avatar size="20" color="rgb(54, 162, 235)" class="mr-2"></v-avatar> SMS</div>
                                        <div class="mb-2"><v-avatar size="20" color="rgb(255, 205, 86)" class="mr-2"></v-avatar> MMS</div>
                                        <v-alert border='left' dense v-if="roi == 0" :icon="'$warning_s'" width="unset" text type="info" class="mb-0 mt-6">
                                            <h6 class="info--text" style="padding-bottom: 0; font-size:16px;">{{$t('calculateRoiToSeeSource')}}</h6>
                                        </v-alert>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions class="text-center justify-center pb-4 pt-0">
                                <a @click="calculRoi">{{$t('CalculateROI')}}</a>
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" class="pt-0 px-2" v-if="!$func.hasRight('profile/is_manager')">
                    <DatatableV2 :displayTitle="false" :flat="false" @openLibraryDialog="openLibraryDialog" :url="apiUrl" :key="currentKey" ref="datatableOpe" />
                    <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
                </v-col>
                <v-col cols="12" class="pa-1">
                    <div class="px-1 pt-1 pb-1 pt-0" style="height:100%;">
                        <marketingCalendar class="" :packId="2" :operations="statsOpe"/>
                    </div>
                </v-col>
            </v-row> 
        </v-col>
        <v-menu
            v-model="showCalculateROI"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            :close-on-content-click="false"
            >
            <v-card>
                <v-card-title style="font-size:18px;" class="pink--text">{{ $t('calculateROI') }}</v-card-title>
                <v-card-text>
                    <p>{{ $t('iinvested')+" "+this.invested+"€"+" "+$t('andhad')+" "+this.submissions+" "+$t('submissions') }}</p>
                    <p class="input-form">
                        <label for="">{{ $t('addmymedianbasket') }}</label>
                        <v-text-field dense hide-details type="number" v-model="basket" solo flat></v-text-field>
                    </p>
                    <p class="input-form">
                        <label for="">{{ $t('addmytransfo') }}</label>
                        <v-text-field solo flat dense hide-details type="number" v-model="transfo"></v-text-field>
                    </p>
                    <div class="input-form">
                        <label for=""><b>{{ $t('myROIis') }}</b></label>
                        <div class="d-flex input-form align-center">
                            <v-text-field solo flat dense hide-details readonly type="number" class="mr-2" v-model="roi"></v-text-field>%
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="packId == 4 || (packId == 3 && !$func.hasRight('profile/is_marketing'))" :key="statsMarketing+'4'">
        <v-col cols="12">
            <v-row class="pa-4">
                <template v-for="(filter, index) in filtersMarketing">
                    <vDaterangePicker v-if="filter.type == 'dateperiod'" mode="past" v-model="filter.value" @change="reloadDataLMS" class="mr-6" :label="index" :key="'filter_'+index"/>
                    <v-select v-else-if="filter.type == 'select'" :no-data-text="$t('noData')" multiple v-model="filter.value" class="mr-6 mt-0 pt-0"  @change="reloadDataLMS" :items="filter.items" style="max-width:200px;" :label="$t(index)" :key="'filterdate_'+index"/>
                </template>
            </v-row>
        </v-col>
        <v-col cols="4" class="px-2">
            <receivedLeadComponent @launchExportLeads="launchExportLeads" :stats="statsData.stats"/>
        </v-col>
        <v-col cols="4" class="px-2">
            <activeLeadComponent :stats="statsData.stats"/>
            <ClosedLeadComponent class="mt-4" :stats="statsData.stats"/>
        </v-col>
        <v-col cols="4" class="px-2">
            <indicatorsLeadComponent :stats="statsData.indicators"/>
        </v-col>
        <v-dialog persistent width="400" v-model="showDownload">
            <v-card>
                <v-card-text class="pa-6">
                    <h5 class="icon-title">
                    {{$t('downloadReady')}}
                    <v-btn @click="showDownload = false" text class="square">
                        <v-icon small>$close</v-icon>
                    </v-btn>
                    </h5>
                    <div class="text-center mt-4 mb-2">
                        <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="$func.hasRight('profile/is_marketing')" :key="statsMarketing">
        <v-col cols="12">
            <homeFiltersComponent v-if="stats && stats.other && stats.other.filters" @removeFilter="removeFilter" :filters="stats.other.filters"/>
        </v-col>
        <v-col cols="12" lg="5" xl="4" class="px-2">
            <todosComponent v-if="stats && stats.data && stats.data.todos" :todos="stats.data.todos.slice(0, 4)" />
            <marketingCalendar v-if="stats && stats.data " class="mt-4" :operations="stats.data.operations"/>
        </v-col>
        <v-col cols="12" lg="7" xl="8" class="px-2">
            <v-row dense>
                <v-col cols="12">
                    <marketingStats v-if="stats && stats.data" :stats="stats.data.stats" />
                    <marketingMsgSent v-if="stats && stats.data" :msgSent="stats.data.message_sent" />
                    <marketingRetroplanning v-if="stats && stats.data" :retroplannings="stats.data.retroplanning" />
                    <alertHomeComponent v-if="stats && stats.data && alertAvailable" :alerts="stats.data.alerts" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="apiUrl">
        <v-col cols="12" class="py-0" v-if="$func.hasRight('profile/is_manager') && !$store.state.auth.user.is_presta">
            <v-switch class="switchMe" @change="changeFor" v-model="justMe" inset hide-details :label="$t('onlyMyNumbers')"></v-switch>
        </v-col>
        <v-col cols="4" class="pr-4">
            <v-card v-if="stats && stats.ranking" color="primary" class="mb-4">
                <v-card-text>
                    <div style="position :absolute; top:10px; right:10px; text-align:center;" v-if=" user.coach_detail &&  user.coach_detail.level">
                        <b style="color:white; margin-bottom:5px;">Niveau {{ user.coach_detail.level }}</b>
                        <div  style="display:flex;">
                            <div v-for="index in 5" :class="index <= user.coach_detail.level ? 'dotLevel active' : 'dotLevel'"></div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-center">
                        <div>
                            <v-img contain width="140" :src="`${publicPath}`+'img/trophee.png'"></v-img>
                        </div>
                        <div>
                            <p class="mb-0" v-if="generalRanking && stats.ranking && stats.ranking.general">
                                <span class="rankingNumber">{{ generalRanking }}<sup v-if="generalRanking == 1">er</sup><sup v-else>e</sup></span><span class="onRanking">/{{ stats.ranking.general.length }}</span>
                            </p>
                            <p class="mb-0" style="color:white; font-size:16px; margin-top:5px; margin-left:10px;">{{ $t('generalRankingThisYear') }}</p>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <HomeNumberStatComponent v-if="stats && stats.mininumbers" class="mb-1" :mini="true" :switchLinkToUser="switchLinkToUser" :data="stats.mininumbers"  :key="'mini_'+statsKey"/>
            <missionCalendar v-if="stats && stats.missions" class="" :missions="stats.missions" :indisponibilities="stats.indisponibilities" @initData="initData" />
            <marketingCalendar v-if="stats && stats.ope" class="" :operations="stats.ope"/>
            <homeCalendarComponent  class="mt-0" v-if="stats && stats.rdvs " :data="stats.rdvs" :key="'rdv_'+statsKey"/>
        </v-col>
        <v-col cols="8">
            <v-row  v-if="stats && stats.ranking && ranks" class="mb-1">
                <v-col cols="3" v-for="(rank, name) in ranks" :key="name" style="height:195px;">
                    <v-card class="h-100" @click="openDetailledStats(name)">
                        <v-card-text class=" h-100 d-flex flex-column justify-space-around align-center">
                            <div :class="getMedalClass(rank.rank)">
                                {{rank.rank }}<sup v-if="rank.rank == 1">er</sup><sup v-else>e</sup>
                            </div>
                            <div class="rankingCount primary--text">
                                <b>{{ rank.count }}<span v-if="name == 'satisfaction'">/10</span><span v-if="name == 'ca_influence'">€</span></b>
                            </div>
                           <div class="black--text">{{  $t(name+"_coach") }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <HomeNumberStatComponent v-if="stats && stats.numbers" class="mb-2" :data="stats.numbers"/>
            <v-card class="pa-2 px-4">
                <DatatableV2 :displayTitle="true" :dense="true" :flat="true" :url="apiUrl"/>
            </v-card>
        </v-col>
        <v-dialog width="700" scrollable v-model="yearlyRankingPopup">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:start;">
                        <div>
                            <v-btn text dark depressed @click.native="yearlyRankingPopup = false">
                                <v-icon small color="white" class="mr-4" >$prev</v-icon>
                                {{  $t('back') }}
                            </v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text>
                    <div class="my-3"><b style="font-size:16px; color :#000;">{{  $t(yearlyRankingType) }}</b></div>
                    <BarComponent :dataSet="graphData" style="height:400px; width:100%;" :key="graphDetailKey"/>
                </v-card-text>
            </v-card>
        </v-dialog>
        
    </v-row>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import marketingCalendar from '@/components/marketingCalendar'
import homeCalendarComponent from '@/components/homeCalendarComponent'
import DatatableV2 from '@/components/DatatableV2'
import HomeNumberStatComponent from '../../../components/dashboardComponent/HomeNumberStatComponent.vue'
import missionCalendar from '@/components/missionCalendar.vue'
import BarComponent from '@/components/graphs/BarComponent'
import vDaterangePicker from '@/components/forms/inputs/v-daterange-picker.vue'
import alertHomeComponent from '@/components/dashboardComponent/alertHomeComponent.vue'
import homeFiltersComponent from '@/components/homeFiltersComponent'
import todosComponent from '@/components/dashboardComponent/todosComponent'
import marketingStats from '@/components/marketingStats'
import marketingMsgSent from '@/components/marketingMsgSent'
import marketingRetroplanning from '@/components/marketingRetroplanning'
import marketingStatPack from '../../../components/marketingStatPack.vue'
import DonutComponentROI from '@/components/graphs/DonutComponentROI.vue'
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import receivedLeadComponent from '@/components/statsPackLM/receivedLeadComponent.vue'
import activeLeadComponent from '@/components/statsPackLM/activeLeadComponent.vue'
import ClosedLeadComponent from '@/components/statsPackLM/closedLeadComponent.vue'
import indicatorsLeadComponent from '@/components/statsPackLM/indicatorsLeadComponent.vue'
export default {
    name: "HomeDashboard",
    components: {
        homeCalendarComponent, BarComponent,
        LibraryDialogComponent, receivedLeadComponent, activeLeadComponent,ClosedLeadComponent,indicatorsLeadComponent,
        marketingCalendar, DatatableV2, HomeNumberStatComponent, missionCalendar,alertHomeComponent,homeFiltersComponent,todosComponent,marketingStats,marketingMsgSent,marketingRetroplanning,vDaterangePicker, marketingStatPack, DonutComponentROI},
    data() {
        return {
            justMe: true,
            isEditable: false,
            statsData: [],
            statsOpe: [],
            statsKey:0,
            switchLinkToUser: false,
            statsMarketing:0,
            publicPath: process.env.BASE_URL,
            ranks: {},
            yearlyRanking: {},
            yearlyRankingType: "",
            yearlyRankingPopup: false,
            showCalculateROI: false,
            basket: null,
            transfo: null,
            x: 0,
            y: 0,
            invested: 0,
            submissions : 0,
            graphKey: 0,
            dataSet:{
                labels: ["leads actifs", "leads clos"],
                total:450,
                datasets : [{
                    label: 'My First Dataset',
                    data: [300, 150],
                    backgroundColor: [
                        'rgb(200, 200, 200)'
                    ],
                    hoverOffset: 4
                }]
            },
            filtersMarketing: [], 
            graphData:{ 
                labels: [],
                datasets: [{
                    label: 'Moyenne personnelle',
            
                    data: [10, 20, 30, 40, 50, 60, 70],
                    backgroundColor: ['#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459']
                },{
                    label: 'Moyenne de l\'équipe',
                   
                    data: [10, 20, 30, 40, 50, 60, 70],
                    backgroundColor: ['#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666']
                }],
                options: {
                    responsive:true,
                    maintainAspectRatio: false, 
                    onClick:this.handle,
                    layout: {
                        padding: {
                            left: 0,
                            right: 0, 
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: false
                    },
                    animation: {
                        duration:0,
                    },
                    title:{
                        display: false,
                        text: "test"
                    },
                    scales:{
                        yAxes: [{
                            beginAtZero: true,
                            stacked: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            beginAtZero: true,
                            stacked: false
                        }],
                    },
                }
            },
            graphDetailKey:0,
            currentKey:0,
            formLib: null,
            libType: "OPERATIONTYPE",
            showLibraryDialog: false,
            downloadUrl:'',
            showDownload: false,
            api: this.$store.state.auth.apiUrl,
        }
    },
    destroyed() {
        this.$store.dispatch('SET_LOADER_DASHBOARD', false)
    },
    watch: {
        stats() {
            this.statsKey++;
            return this.statsData
        },
        roi(val) {
            if(val != 0){
                //calcul les datas de graphs
                this.dataSet.total = this.roi
                let n = 0
                // this.stats.sources.forEach((element, index) => {                   
                //     this.dataSet.datasets[0].data[n] = ((((element.submissions * this.basket) * (this.transfo / 100)) - element.invested) / element.invested) * 100
                //     n++
                // });
                for (const key in this.stats.sources) {
                    if (Object.prototype.hasOwnProperty.call(this.stats.sources, key)) {
                        const element = this.stats.sources[key];
                        if(element.submissions > 0 && element.invested > 0){
                            this.dataSet.datasets[0].data[n] = ((((((element.submissions * this.basket) * (this.transfo / 100)) - element.invested) / element.invested) * 100) * 100) / this.roi
                        } else {
                            this.dataSet.datasets[0].data[n] = 0
                        }
                        n++
                    }
                }
                this.dataSet.datasets[0].backgroundColor = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
                this.graphKey++;
            } else {
                //reinit les datas de graphs
                this.dataSet = {
                    labels: ["test1", "test2", "test3"],
                    total:0,
                    datasets : [{
                        label: 'My First Dataset',
                        data: [100],
                        
                        backgroundColor: [
                            'rgb(200, 200, 200)'
                        ],
                        hoverOffset: 4
                    }]
                }, 
                this.graphKey++;
            }
        }
    },
    methods: {
        launchExportLeads(){
            GenericDataService.postData('/home/exportLeadsPackLM', {filters : this.filtersMarketing}).then((response)=>{
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload;
                    this.showDownload = true;
                    
                }
            })
        },
        reloadData(){
            GenericDataService.postData('/home/getStatsPackMarketing', {filters : this.filtersMarketing}).then((response)=> {
                this.statsData = response.data.data
                //this.statsMarketing++;
            })
        },
        reloadDataLMS(){
            GenericDataService.postData('/home/getStatsPackLM', {filters : this.filtersMarketing}).then((response)=>{
                this.statsData = response.data.data
                this.statsMarketing++;
            })
        },
        getItems(url){
            GenericDataService.getData(url).then((response) => {
                return response.data.data
            })
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        calculRoi(e){
            //popup calculate ROI
            this.x = e.clientX
            this.y = e.clientY
            this.showCalculateROI = true
            console.log(e)	
        },
        openDetailledStats(type) {
            this.yearlyRanking = {}
            this.graphData.datasets[0].data  = []
            this.graphData.datasets[1].data  = []
            GenericDataService.getData('/user/getYearlyRanking?type='+type).then((response) => {
                let rankingYearly = response.data.data
                for (const month in rankingYearly) {
                    const element = rankingYearly[month]['stats'];
                    this.yearlyRanking[month] = element[type]
                }
                for (const month in this.yearlyRanking) {
                    const element = this.yearlyRanking[month];
                    const coach_number = rankingYearly[month]['nb_coachs']
                    this.graphData.datasets[0].data.push(element.find((e) => e.id == this.user.id).count)
                    this.graphData.datasets[1].data.push((element.reduce((a, b) => a + parseFloat(b.count), 0) / parseInt(coach_number)).toFixed(2))
                }
                
                this.graphData.labels = Object.keys(this.yearlyRanking)
                this.yearlyRankingType = type
                this.yearlyRankingPopup = true
                this.graphDetailKey++   
            })
        },
        getMedalClass(rank){
            let addClass = ''
            switch (rank) {
                case 1:
                    addClass = 'gold'
                    break;
                case 2:
                    addClass = 'silver'
                    break;
                case 3:
                    addClass = 'bronze'
                    break;
            }
            return 'medal '+addClass
        },
        removeFilter(filter, val){
             if(Array.isArray(filter.value)){
                 let index = filter.value.findIndex((e) => e.value == val.value)
                 if(index >=0) 
                     filter.value.splice(index, 1)
             } else {
                 filter.value = ""
            }
            

             let filters = {}
             this.statsData.other.filters.forEach(element => {
                     filters[element.name] = element.value
            });
             this.filterDashboard({filters : filters});
         },
         filterHome(filterSelected){
             for (const filterName in filterSelected) {
                 let filter = this.statsData.other.filters.find((e) => e.name == filterName)
                 filter.value = filterSelected[filterName]
             }

             let payload = {filters : filterSelected}
             this.filterDashboard(payload);
        },
        filterDashboard(payload) {

             GenericDataService.postData('/home/getStats', payload).then((response)=>{
                 this.statsData = response.data.data
                this.statsMarketing++;
                this.$store.dispatch('SET_LOADER_DASHBOARD', false)
             })
         },
        initData(){
            if(this.packId == 4 || (this.packId == 3 && !this.$func.hasRight('profile/is_marketing'))){ 
                GenericDataService.postData('/home/getStatsPackLM', []).then((response)=>{
                        this.statsData = response.data.data
                        this.filtersMarketing = response.data.data.filters
                        this.statsMarketing++;
                        this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                })
                
            } else if(this.packId == 2 || (this.packId == 3 && this.$func.hasRight('profile/is_marketing'))){
                GenericDataService.getData('/home/getOpeStatsPackMarketing').then((response) => {
                    this.statsOpe = response.data.data
                    GenericDataService.postData('/home/getStatsPackMarketing', []).then((response)=>{
                        this.statsData = response.data.data
                        this.filtersMarketing = response.data.data.filters
                        
                        for (const key in this.filtersMarketing) {
                            if (Object.prototype.hasOwnProperty.call(this.filtersMarketing, key)) {
                                const element = this.filtersMarketing[key];
                                if(element.type == 'select' && element.hasOwnProperty('selectDataUrl')){
                                    GenericDataService.getData(element.selectDataUrl).then((response) => {
                                        element.items = response.data.data
                                    })
                                }
                            }
                        }
                        this.statsMarketing++;
                        if(this.$func.hasRight('profile/is_manager')){
                            let resultStat = response.data.data.stats.find((e) => e.label == 'results').data
                            this.submissions = resultStat.find((e) => e.label == 'submissions').value
                            this.invested = resultStat.find((e) => e.label == 'invested').value
                        }
                        this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                    })
                })
                
            } else if(this.$func.hasRight('profile/is_marketing')){
                GenericDataService.postData('/home/getStats', []).then((response)=>{
                    this.statsData = response.data.data
                    this.statsMarketing++;
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                })
            } else {
                let filterstest = {}
                if(this.$func.hasRight('profile/is_manager')){
                    filterstest = {
                        filters: {
                            justMe: this.justMe
                        }
                    }
                } else {
                    filterstest = {
                        filters: {
                            justMe: true
                        }
                    }
                }
                GenericDataService.postData('/home/getStatsV4', filterstest).then((response)=>{
                    this.statsData = response.data.data
                    if(this.statsData.ranking){
                        this.ranks = {}
                        for (const key in this.statsData.ranking) {
                            if (Object.hasOwnProperty.call(this.statsData.ranking, key)) {
                                const element = this.statsData.ranking[key];
                                if(key != 'general')
                                    this.ranks[key] = element.find((e) => e.id == this.user.id)
                            }
                        }
                    }
                    this.statsKey++;
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                }).catch((e) => {
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                });
            }
        },
        changeFor($evt){
            this.switchLinkToUser = this.justMe
            this.initData()
        }
    },
    computed: {
        apiUrl(){
            if(this.packId == 2){
                return "/home/getOperationListPackMarketing"
            } else {
                return "/home/getCurrentOperations"
            }
        },
        roi(){
            if(this.basket && this.transfo && this.invested && this.submissions){
                return  Math.floor(((((this.submissions * this.basket) * (this.transfo / 100)) - this.invested) / this.invested) * 100)
            } else {
                return 0
            }
        },
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        generalRanking() {
            let userRanking = this.stats.ranking.general.find((e) => e.id == this.user.id);
            return userRanking ? userRanking.position : 0;
        },
        alertAvailable(){
            for (const key in this.$store.state.auth.currentUser.accountParameters.alert) {
                if (Object.hasOwnProperty.call(this.$store.state.auth.currentUser.accountParameters.alert, key)) {
                    const element = this.$store.state.auth.currentUser.accountParameters.alert[key];
                    if(element)
                        return true
                }
            }
            return false;
        },
        user(){
            return this.$store.state.auth.user
        },
        stats() {
            return this.statsData
        }
    },
    created() {
        if((this.$func.hasRight('profile/is_manager') && !this.$store.state.auth.user.is_presta) || this.$func.hasRight('profile/is_vendor')){
            this.switchLinkToUser = true
        }
        this.$store.dispatch('SET_LOADER_DASHBOARD', true).then((e) => {
            this.initData()
        })
    },
    // mounted(){
    //     this.$store.dispatch('SET_LOADER_DASHBOARD', true).then((e) => {
    //         this.initData()
    //     })
    // }
}
</script>
<style lang="scss">
.statMarket {
    .v-list-item__avatar {
        justify-content: center !important;
    }
}
.medal {
        background-color: #000;
        color:white;
        border-radius: 50%;
        padding:5px;
        width:50px;
        height:50px;
        font-size:22px;
        font-weight: bold;
        flex-shrink: 0;
        display:flex;
        align-items: center;
        justify-content: center;
        sup {
            font-size:14px;
        }
        &.gold {
            background-color: #e3c034;
        }
        &.silver {
            background-color: #b7b7b7;
        }
        &.bronze {
            background-color: #cca76e;
        }
    }
.rankingCount {
    font-size: 28px; 
    b {
        font-weight:900;
    }  
}
.dotLevel {
    width:10px;
    height:10px; 
    background-color: #FFF;
    border-radius: 5px;
    margin-right:2px;
    margin-left:2px;
    &.active {
      background-color: #ff2987;
    }
  }
    .switchMe {
         .v-input--switch__track {
           height:20px !important;
           width:40px !important;
        }
        .v-input--switch__thumb {
            width: 12px !important;
            height: 12px !important;
        }
        .v-input--selection-controls__ripple {
            width: 26px !important;
            height: 26px !important;
        }
        .v-label {
            margin-bottom:8px !important;
        }
    }
    
    .rankingNumber {
        color:white;
        font-size: 48px;
      font-weight: bold;
      margin-left:10px;

    }
    .onRanking {
        color:white;
        font-size: 32px;
      font-weight: bold;
      margin-left:10px;
    }
    .alertNoRoi {
        background-color:rgba(255,255,255,0.6); 
        z-index:15; 
        position:absolute; 
        width:100%; 
        height:100%; 
        top:0; 
        left:0;
    }
</style>