<template>
     <v-card class="h-100 d-flex flex-column">
        <v-card-title>
           <h2 style="min-height:18px !important;" class="pa-3">{{ $t('reveivedLeads') }}</h2>
        </v-card-title>
        <v-card-text class="d-flex flex-column flex-grow-1 justify-space-between">
            <DonutComponent v-if="dataSet2" :dataSet="dataSet2" :height="350" :legend="false"/>
            <div class="legend mt-6 d-flex flex-column align-center">
                <div>
                    <div class="d-flex mb-2"  style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(91, 184, 93)'"></span>{{ _.get(stats , 'active.total') ? stats.active.total : 0 }} {{$t('activeLeads')}}
                    </div>
                    <div class="d-flex" style="font-size:16px;">
                        <span :style="' margin-right: 10px; width: 20px; height:20px; border-radius:50%; display:inline-block; background-color:rgb(217, 84, 79)'"></span>{{ _.get(stats , 'closed.total') ? stats.closed.total : 0 }} {{$t('closedLeads')}}
                    </div>
                </div>
            </div>
            <div>
                <v-btn color="primary" :disabled="stats && stats.total == 0" @click="launchExportLeads" class="mt-6" block depressed>{{ $t('exportLeads') }}</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import DonutComponent from '@/components/graphs/DonutComponent';
export default {
    name: 'ReceivedLeadComponent',
    components : {DonutComponent},
    props: ['stats'],
    data() {
        return {
            dataSet:{
                labels: ["Actifs", "Clos"],
                total:450,
                datasets : [{
                    label: 'My First Dataset',
                    data: [300, 150],
                    backgroundColor: [
                        'rgb(91, 184, 93)',
                        'rgb(217, 84, 79)'
                    ],
                    hoverOffset: 4
                }]
            }, 
        }
    },
    methods: {
        launchExportLeads () {
            this.$emit('launchExportLeads');
        },
    },
    computed: {
        
        dataSet2() {
            if(this.stats) {
                let dataset = this._.cloneDeep(this.dataSet);
                dataset.total = this.stats.total;
                dataset.datasets[0].data = [this.stats.active.total, this.stats.closed.total];
                if(this.stats.active.total == 0 && this.stats.closed.total == 0){
                    dataset.datasets[0].data = [100];
                    dataset.labels = ['Aucune donnée'];
                    dataset.datasets[0].backgroundColor = ['#DDD'];
                }
                return dataset;
            }
            return false
        }
    },
    
};
</script>

<style>
/* Your component styles go here */
</style>